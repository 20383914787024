import logo from "./logo.svg";
import "./App.css";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import backsound from "./assets/sound.mp3";

function App() {
  const player = useRef(null);
  const [start, setStart] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [state, setState] = useState("IDLE");

  const [hScreen, setHScreen] = useState(0);
  const [wScreen, setWScreen] = useState(0);
  const [cSize, setCSize] = useState(0);

  const [play] = useSound(backsound, {
    loop: true,
  });

  useEffect(() => {
    let h = window.innerHeight;
    let w = h * (1080 / 1920);

    if (window.innerWidth > w) {
      setHScreen(h);
      setWScreen(w);
      setCSize(0.23 * w);
    } else {
      let w = window.innerWidth;
      let h = w * (1920 / 1080);
      setHScreen(h);
      setWScreen(w);
      setCSize(0.23 * w);
    }
  }, [window]);


  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  //state =  IDLE
  const onReady = () => {
    handlePlay();
  };

  const onSeek = (e) => {};

  const handlePlay = () => {
    setIsPlay(true);
  };

  const handleStop = () => {
    setIsPlay(false);
  };

  useEffect(() => {
    if (player) {
      console.log(player.current);
    }
  }, [player]);

  const onProgress = (val) => {
    console.log(state);
    if (state === "IDLE") {
      if (val.playedSeconds > 5) {
        player.current.seekTo(0, "seconds");
      }
    }

    if (state === "CLICKED_1") {
      if (val.playedSeconds > 11) {
        setState("IDLE");
        player.current.seekTo(2, "seconds");
      }
    }

    if (state === "CLICKED_2") {
      if (val.playedSeconds > 17) {
        setState("IDLE");
        player.current.seekTo(2, "seconds");
      }
    }

    if (state === "CLICKED_3") {
      if (val.playedSeconds > 23) {
        setState("IDLE");
        player.current.seekTo(2, "seconds");
      }
    }
  };

  const handle1 = () => {
    setState("CLICKED_1");
    player.current.seekTo(6, "seconds");
  };

  const handle2 = () => {
    setState("CLICKED_2");
    player.current.seekTo(12, "seconds");
  };

  const handle3 = () => {
    setState("CLICKED_3");
    player.current.seekTo(18.2, "seconds");
  };

  const handleEnd = () => {
    handlePlay();
    setState("IDLE");
    player.current.seekTo(0, "seconds");
  };

  const handleStart = () => {
    setTimeout(() => {
      play();
    }, 200);
    setStart(true);
  };

  return (
    <div className="w-screen h-screen overflow-hidden bg-black flex justify-center items-center">
      <>
        {start ? (
          <>
            <div className="absolute">
              <div
                class="relative bg-red-300 opacity-0 z-50"
                style={{ width: wScreen, height: hScreen }}
              >
                <div
                  onClick={() => handle1()}
                  className="absolute  bottom-[22%] left-[15%] rounded-full bg-white"
                  style={{ width: cSize + "px", height: cSize + "px" }}
                ></div>
                <div
                  onClick={() => handle2()}
                  className="absolute  bottom-[22%] right-[15%] rounded-full bg-white"
                  style={{ width: cSize + "px", height: cSize + "px" }}
                ></div>
                <div
                  onClick={() => handle3()}
                  className="absolute  bottom-[10%] left-1/2 -translate-x-1/2 rounded-full bg-white"
                  style={{ width: cSize + "px", height: cSize + "px" }}
                ></div>
              </div>
            </div>
            <ReactPlayer
              playsinline
              ref={player}
              onProgress={onProgress}
              onEnded={() => handleEnd()}
              onSeek={onSeek}
              onReady={onReady}
              playing={isPlay}
              url="/assets/video.mp4"
              width="100%"
              height="100%"
            />
          </>
        ) : (
          <>
            <button
              className="text-black font-semibold py-2 px-4 bg-white rounded-xl"
              onClick={() => handleStart()}
            >
              START
            </button>
          </>
        )}
      </>
    </div>
  );
}

export default App;
